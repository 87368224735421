'use client';
import { QueryClient, QueryClientProvider as QueryProvider } from '@tanstack/react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // staleTime: 60000, // dữ liệu mới trong 1 phút
      // gcTime: 300000 // dữ liệu được cache trong 5 phút
    },
  },
});

const QueryClientProvider = ({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) => {
  return <QueryProvider client={queryClient}>{children}</QueryProvider>;
};

export default QueryClientProvider;
