import(/* webpackMode: "eager", webpackExports: ["ConfigProvider","App"] */ "__barrel_optimize__?names=App,ConfigProvider!=!/app/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/fonts/HelveticaNeueLight.otf\",\"variable\":\"--font-hneu\",\"display\":\"swap\"}],\"variableName\":\"myFont\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/antd/dist/reset.css");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/providers/AntdStyledComponentsRegistry.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/providers/QueryClientProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/providers/SwrProvider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/globals.css");
