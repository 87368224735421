'use client';

import { authApi } from '@/api-client';
import { SWRConfig } from 'swr';

const SwrProvider = ({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) => {
  return (
    <SWRConfig
      value={{
        fetcher: () => authApi.profile(),
        revalidateOnFocus: true,
        revalidateOnReconnect: true,
      }}
    >
      {children}
    </SWRConfig>
  );
};

export default SwrProvider;
